@mixin override {
  .lc-infobox {
    .lc-infobox-title {
      span {
        display: inline;
        em {
          font-style: normal;
          @include font-medium;
        }
      }
    }
  }
  .slick-next,
  .slick-prev {
    width: 90px !important;
  }

  .lc-busscolaire {
    grid-template-columns: repeat(4, 1fr) !important;
  }
  
  .leaflet-control-attribution {
    @include mobile {
      pointer-events: none;
      cursor: default;
    }
  }
}
